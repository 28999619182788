// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.note-type-Fleeting,
.note-type-Reference,
.note-type-Literature,
.note-type-Permanent {
    padding: 2px 10px;
    border: none;
    border-radius: 24px;
    font-size: 12px;
}

.note-type-Fleeting {
    background-color: var(--theme-adaptive-900);
    color: var(--theme-adaptive-900i);
}

.note-type-Reference {
    background-color: #9b6336;
    color: #fff;
}

.note-type-Literature {
    background-color: #f1bb46;
    color: #000;
}

.note-type-Permanent {
    background-color: var(--theme-success-700);
    color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/basicui-styles/note_type.css"],"names":[],"mappings":"AAAA;;;;IAII,iBAAiB;IACjB,YAAY;IACZ,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,2CAA2C;IAC3C,iCAAiC;AACrC;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,0CAA0C;IAC1C,WAAW;AACf","sourcesContent":[".note-type-Fleeting,\r\n.note-type-Reference,\r\n.note-type-Literature,\r\n.note-type-Permanent {\r\n    padding: 2px 10px;\r\n    border: none;\r\n    border-radius: 24px;\r\n    font-size: 12px;\r\n}\r\n\r\n.note-type-Fleeting {\r\n    background-color: var(--theme-adaptive-900);\r\n    color: var(--theme-adaptive-900i);\r\n}\r\n\r\n.note-type-Reference {\r\n    background-color: #9b6336;\r\n    color: #fff;\r\n}\r\n\r\n.note-type-Literature {\r\n    background-color: #f1bb46;\r\n    color: #000;\r\n}\r\n\r\n.note-type-Permanent {\r\n    background-color: var(--theme-success-700);\r\n    color: #fff;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
