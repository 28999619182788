// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.note-label-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -10px;
}

.note-label {
    display: grid;
    grid-auto-flow: column;
    column-gap: 10px;
    align-items: center;
    text-align: left;
    padding: 4px 12px;
    border-radius: 4px;
    font-size: 12px;
    background-color: var(--theme-white-500);
    color: var(--theme-white-500i);
    margin: 0 10px 10px 0;
    border: none;
    transition: background-color 250ms ease-in-out, color 250ms ease-in-out;
}

.basicui-dark .note-label {
    background-color: var(--theme-black-extended-700);
    color: var(--theme-black-extended-700i);
}

button.note-label:hover,
button.note-label:focus,
button.note-label:active {
    background-color: var(--theme-primary-transparent-400);
    color: var(--theme-primary-transparent-400i);
}

.note-label button {
    background-color: transparent;
    border: none;
    color: inherit;
    cursor: pointer;
}

`, "",{"version":3,"sources":["webpack://./src/basicui-styles/note_label.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,wCAAwC;IACxC,8BAA8B;IAC9B,qBAAqB;IACrB,YAAY;IACZ,uEAAuE;AAC3E;;AAEA;IACI,iDAAiD;IACjD,uCAAuC;AAC3C;;AAEA;;;IAGI,sDAAsD;IACtD,4CAA4C;AAChD;;AAEA;IACI,6BAA6B;IAC7B,YAAY;IACZ,cAAc;IACd,eAAe;AACnB","sourcesContent":[".note-label-list {\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    margin-bottom: -10px;\r\n}\r\n\r\n.note-label {\r\n    display: grid;\r\n    grid-auto-flow: column;\r\n    column-gap: 10px;\r\n    align-items: center;\r\n    text-align: left;\r\n    padding: 4px 12px;\r\n    border-radius: 4px;\r\n    font-size: 12px;\r\n    background-color: var(--theme-white-500);\r\n    color: var(--theme-white-500i);\r\n    margin: 0 10px 10px 0;\r\n    border: none;\r\n    transition: background-color 250ms ease-in-out, color 250ms ease-in-out;\r\n}\r\n\r\n.basicui-dark .note-label {\r\n    background-color: var(--theme-black-extended-700);\r\n    color: var(--theme-black-extended-700i);\r\n}\r\n\r\nbutton.note-label:hover,\r\nbutton.note-label:focus,\r\nbutton.note-label:active {\r\n    background-color: var(--theme-primary-transparent-400);\r\n    color: var(--theme-primary-transparent-400i);\r\n}\r\n\r\n.note-label button {\r\n    background-color: transparent;\r\n    border: none;\r\n    color: inherit;\r\n    cursor: pointer;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
