export const GET_AUTH = 'GET_AUTH';
export const ADD_AUTH = 'ADD_AUTH';
export const REMOVE_AUTH = 'REMOVE_AUTH';

export const GET_PROFILE = 'GET_PROFILE';
export const SET_PROFILE = 'SET_PROFILE';

export const GET_USER = 'GET_USER';
export const ADD_USER = 'ADD_USER';
export const FETCH_ALL_USERS = 'FETCH_ALL_USERS';
export const FETCH_ALL_ROLES = 'FETCH_ALL_ROLES';
export const GET_ROLE = 'GET_ROLE';
export const ADD_ROLE = 'ADD_ROLE';

export const FETCH_CATEGORY = 'FETCH_CATEGORY';

export const REFRESH_SPACES = 'REFRESH_SPACES';

export const RELOAD_PROJECTS = 'RELOAD_PROJECTS';

export const RELOAD_ENDPOINTS = 'RELOAD_ENDPOINTS';

export const CATEGORY_ITEMS_UPDATE = 'CATEGORY_ITEMS_UPDATE';

export const INCOME_CATEGORY_ITEMS_FETCH_AND_SET =
  'INCOME_CATEGORY_ITEMS_FETCH_AND_SET';
export const INCOME_CATEGORY_ITEMS_UPDATE = 'INCOME_CATEGORY_ITEMS_UPDATE';

export const TAG_ITEMS_UPDATE = 'TAG_ITEMS_UPDATE';

export const EXPENSE_ITEMS_FETCH_AND_APPEND = 'EXPENSE_ITEMS_FETCH_AND_APPEND';
export const EXPENSE_ITEMS_FETCH_AND_SET = 'EXPENSE_ITEMS_FETCH_AND_SET';
export const EXPENSE_ITEMS_UPDATE = 'EXPENSE_ITEMS_UPDATE';
export const EXPENSE_ITEMS_UPDATE_PAGINATION =
  'EXPENSE_ITEMS_UPDATE_PAGINATION';
export const EXPENSE_ITEMS_UPDATE_FILTER = 'EXPENSE_ITEMS_UPDATE_FILTER';

export const RECEIPT_ITEMS_FETCH_AND_APPEND = 'RECEIPT_ITEMS_FETCH_AND_APPEND';
export const RECEIPT_ITEMS_FETCH_AND_SET = 'RECEIPT_ITEMS_FETCH_AND_SET';
export const RECEIPT_ITEMS_UPDATE = 'RECEIPT_ITEMS_UPDATE';
export const RECEIPT_ITEMS_UPDATE_PAGINATION =
  'RECEIPT_ITEMS_UPDATE_PAGINATION';
export const RECEIPT_ITEMS_UPDATE_FILTER = 'RECEIPT_ITEMS_UPDATE_FILTER';

export const INCOME_ITEMS_FETCH_AND_APPEND = 'INCOME_ITEMS_FETCH_AND_APPEND';
export const INCOME_ITEMS_FETCH_AND_SET = 'INCOME_ITEMS_FETCH_AND_SET';
export const INCOME_ITEMS_UPDATE = 'INCOME_ITEMS_UPDATE';
export const INCOME_ITEMS_UPDATE_PAGINATION = 'INCOME_ITEMS_UPDATE_PAGINATION';
export const INCOME_ITEMS_UPDATE_FILTER = 'INCOME_ITEMS_UPDATE_FILTER';

export const COMPANY_LIST_FETCH_AND_SET = 'COMPANY_LIST_FETCH_AND_SET';

export const USER_LIST_FETCH_AND_SET = 'USER_LIST_FETCH_AND_SET';

export const FILTER_EXPENSE_LIST_FETCH_AND_SET =
  'FILTER_EXPENSE_LIST_FETCH_AND_SET';

export const TAG_LIST_FETCH_AND_SET = 'TAG_LIST_FETCH_AND_SET';




export const NOTE_ITEMS_FETCH_AND_SET = 'NOTE_ITEMS_FETCH_AND_SET';
export const NOTE_ITEMS_UPDATE = 'NOTE_ITEMS_UPDATE';
export const NOTE_ITEMS_APPEND = 'NOTE_ITEMS_APPEND';
export const NOTE_ITEMS_DELETE = 'NOTE_ITEMS_DELETE';

export const LABEL_ITEMS_FETCH_AND_SET = 'LABEL_ITEMS_FETCH_AND_SET';
export const METADATA_DEFINITION_ITEMS_FETCH_AND_SET = 'METADATA_DEFINITION_ITEMS_FETCH_AND_SET';
export const METADATA_VALUE_ITEMS_FETCH_AND_SET = 'METADATA_VALUE_ITEMS_FETCH_AND_SET';
export const COLOR_FILTER_ITEMS_FETCH_AND_SET = 'COLOR_FILTER_ITEMS_FETCH_AND_SET';

export const NOTELINK_ITEMS_FETCH_AND_SET = 'NOTELINK_ITEMS_FETCH_AND_SET';
export const NOTELINK_ITEMS_APPEND = 'NOTELINK_ITEMS_APPEND';
export const NOTELINK_ITEMS_DELETE = 'NOTELINK_ITEMS_DELETE';
export const NOTELINK_ITEMS_DELETE_BY_NOTEREF = 'NOTELINK_ITEMS_DELETE_BY_NOTEREF';

export const NOTELINK_AUTO_ITEMS_FETCH_AND_SET = 'NOTELINK_AUTO_ITEMS_FETCH_AND_SET';
export const NOTELINK_AUTO_ITEMS_APPEND = 'NOTELINK_AUTO_ITEMS_APPEND';
export const NOTELINK_AUTO_ITEMS_DELETE = 'NOTELINK_AUTO_ITEMS_DELETE';
export const NOTELINK_AUTO_ITEMS_REPLACE = 'NOTELINK_AUTO_ITEMS_REPLACE';
export const NOTELINK_AUTO_ITEMS_DELETE_BY_NOTEREF = 'NOTELINK_AUTO_ITEMS_DELETE_BY_NOTEREF';